// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (min-width: 701px) and (max-width: 1120px) {
  .sidebar-item.plain span {
    display: none !important;
  }
  .sidebar-title span {
    display: none !important;
  }
  .sidebar-item.plain img {
    display: block !important;
  }
  .oja-dashboard .oja-dashboard-sidebar {
    width: 77px !important;
  }
  .oja-dashboard .oja-dashboard-content {
    margin-left: 79px !important;
    padding: 0.5em !important;
  }
  .oja-sidebar-container {
    padding-left: 2px !important;
    padding-right: 1px !important;
  }
  .sidebar-content {
    padding-left: 4px !important;
  }
  .sidebar-item img {
    margin-right: 2px !important;
  }
  .oja-ellipsis {
    max-width: 50px !important;
  }
  .order th {
    max-width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .oja-nav-dashboard .oja-nav-logo {
    margin-left: -25px !important;
  }
  .oja-nav-dashboard .oja-nav-form {
    margin-left: -200px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/responsiveness.css"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,wBAAwB;EAC1B;EACA;IACE,yBAAyB;EAC3B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,4BAA4B;IAC5B,yBAAyB;EAC3B;EACA;IACE,4BAA4B;IAC5B,6BAA6B;EAC/B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,4BAA4B;EAC9B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,8BAA8B;EAChC;AACF","sourcesContent":["@media (min-width: 701px) and (max-width: 1120px) {\n  .sidebar-item.plain span {\n    display: none !important;\n  }\n  .sidebar-title span {\n    display: none !important;\n  }\n  .sidebar-item.plain img {\n    display: block !important;\n  }\n  .oja-dashboard .oja-dashboard-sidebar {\n    width: 77px !important;\n  }\n  .oja-dashboard .oja-dashboard-content {\n    margin-left: 79px !important;\n    padding: 0.5em !important;\n  }\n  .oja-sidebar-container {\n    padding-left: 2px !important;\n    padding-right: 1px !important;\n  }\n  .sidebar-content {\n    padding-left: 4px !important;\n  }\n  .sidebar-item img {\n    margin-right: 2px !important;\n  }\n  .oja-ellipsis {\n    max-width: 50px !important;\n  }\n  .order th {\n    max-width: 50px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n  .oja-nav-dashboard .oja-nav-logo {\n    margin-left: -25px !important;\n  }\n  .oja-nav-dashboard .oja-nav-form {\n    margin-left: -200px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
